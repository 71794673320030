import { createRouter, createWebHistory } from 'vue-router';

// import Home from '../views/QrHome.vue';
import Home from '../views/QrPage.vue';
import store from '../store';

const routes = [
    //dashboard
    { path: '/', name: 'Home', component: Home },
    { path: '/qrpage', name: 'QRPage', component: Home },
    {
        path: '/share-target',
        name: 'ShareTarget',
        component: () => import(/* webpackChunkName: "pages-helpdesk" */ '../views/share_target.vue'),
    },
    {
        path: '/pages/about-us',
        name: 'about-us',
        component: () => import(/* webpackChunkName: "pages-helpdesk" */ '../views/pages/about_us.vue'),
    },
    {
        path: '/pages/contact-us',
        name: 'contact-us',
        component: () => import(/* webpackChunkName: "pages-contact-us" */ '../views/pages/contact_us.vue'),
    },
    {
        path: '/pages/privacy-policy',
        name: 'privacy-policy',
        component: () => import(/* webpackChunkName: "pages-privacy-policy" */ '../views/pages/privacy_policy.vue'),
    },
    {
        path: '/pages/terms-conditions',
        name: 'terms-conditions',
        component: () => import(/* webpackChunkName: "pages-privacy-policy" */ '../views/pages/terms_conditions.vue'),
    },
    { path: '/:pathMatch(.*)*', redirect: '/' }
];

const router = new createRouter({
    mode: 'history',
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    const queryParams = to.query; // Access query parameters from the destination route (to)
    const { title, text, url } = queryParams;

    if (to.name === 'ShareTarget') {
        // If the route is the share-target route, save shared data to Vuex store
        store.commit('setShareData', { title, text, url });
    }
  
  console.log('Query Parameters:', queryParams);

    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }
    if (to.path === '/qrpage' && (title || text || url)) {
        next('/');
    } else {
        next();
    }

});

export default router;
