<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">Pages</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>QR Page</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>

        <div class="helpdesk container">
            <div class="helpdesk layout-spacing">
                <div class="hd-header-wrapper">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <h5 class="">QR Scanner</h5>

                            <div class="row">
                                <div class="col-sm-12 col-12 mx-auto">
                                    <div class="d-flex justify-content-center">
                                        <!-- <button type="button" class="btn  mb-2 me-2" data-bs-toggle="modal"
                                            data-bs-target="#qrScannerModal"> -->
                                        <button type="button" class="btn mb-2 me-2" @click="openQRScannerModal">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="256"
                                                height="256" viewBox="0 0 256 256" xml:space="preserve">

                                                <defs>
                                                </defs>
                                                <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                                                    transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                                                    <polygon
                                                        points="85.71,85.71 62.17,85.71 62.17,77.62 77.62,77.62 77.62,62.17 85.71,62.17 "
                                                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(68,68,68); fill-rule: nonzero; opacity: 1;"
                                                        transform="  matrix(1 0 0 1 0 0) " />
                                                    <polygon
                                                        points="27.83,85.71 4.29,85.71 4.29,62.17 12.38,62.17 12.38,77.62 27.83,77.62 "
                                                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(68,68,68); fill-rule: nonzero; opacity: 1;"
                                                        transform="  matrix(1 0 0 1 0 0) " />
                                                    <polygon
                                                        points="12.38,27.83 4.29,27.83 4.29,4.29 27.83,4.29 27.83,12.38 12.38,12.38 "
                                                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(68,68,68); fill-rule: nonzero; opacity: 1;"
                                                        transform="  matrix(1 0 0 1 0 0) " />
                                                    <polygon
                                                        points="85.71,27.83 77.62,27.83 77.62,12.38 62.17,12.38 62.17,4.29 85.71,4.29 "
                                                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(68,68,68); fill-rule: nonzero; opacity: 1;"
                                                        transform="  matrix(1 0 0 1 0 0) " />
                                                    <rect x="0" y="40.95" rx="0" ry="0" width="90" height="8.09"
                                                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(66,198,110); fill-rule: nonzero; opacity: 1;"
                                                        transform=" matrix(1 0 0 1 0 0) " />
                                                </g>
                                            </svg>

                                        </button>

                                        <!-- Modal -->
                                        <div class="modal fade" id="qrScannerModal" :class="{ 'show': showModal }" tabindex="-1" role="dialog"
                                            ref="qrScannerModal" aria-labelledby="exampleModalLabel" aria-hidden="true">

                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title text-center" id="exampleModalLabel">QR
                                                            SCANNER</h5>
                                                        <button type="button" data-dismiss="modal" data-bs-dismiss="modal"
                                                            aria-label="Close" class="btn-close"></button>

                                                    </div>
                                                    <div class="modal-body">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="d-flex flex-column">
                                                                    <qrcode-stream @detect="onDetect" @error="onError"
                                                                        @camera-on="onCameraOn"
                                                                        :track="trackFunctionSelected.value"
                                                                        v-if="!destroyed">
                                                                        <div class="loader mx-auto" v-if="loading"></div>
                                                                    </qrcode-stream>
                                                                    <div class="alert alert-light-warning alert-dismissible border-0 mb-4"
                                                                        role="alert" v-if="scannerError !== ''">
                                                                        <strong>Warning!</strong> {{ scannerError }}
                                                                        <button type="button" class="close"
                                                                            data-bs-dismiss="alert"
                                                                            aria-label="Close">×</button>
                                                                    </div>
                                                                    <p>{{ scannedData }}</p>
                                                                    <div class="d-flex flex-row justify-content-between">
                                                                        <button class="btn btn-primary mb-2 me-2"
                                                                            @click="rescan">Rescan</button>
                                                                        <button class="btn btn-primary mb-2 me-2"
                                                                            @click="redirect"
                                                                            v-if="isValidLink(scannedData)"
                                                                            variant="primary">Redirect</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <h3>QR Generator</h3>
                                    <form class="text-start">
                                        <div class="coming-soon">
                                            <div class="">
                                                <div id="email-field" class="field-wrapper input"
                                                    style="position: relative;display: flex; width: 100%;text-align: left !important;">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        style="position: absolute;top: 11px;color: #4361ee; fill: rgba(27, 85, 226, .239);left: 8px; vertical-align: middle; box-sizing: border-box;text-align: center;"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-at-sign">
                                                        <circle cx="12" cy="12" r="4"></circle>
                                                        <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                                                    </svg>
                                                    <input placeholder="Link" v-model="qrInput" class="form-control"
                                                        style="padding: 10px 97px 10px 45px;" />
                                                    <button type="button" class="btn btn-primary" @click="generateQR"
                                                        style="background-color: #009688 !important;border-color: #009688; box-shadow: none;  align-self: center; position: absolute;  right: 0; padding: 10px 17px;border-bottom-left-radius: 20px;">Generate</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <!-- image of qr size -->
                                    <div class="d-flex flex-column justify-content-center mt-4" style="padding:10px">
                                        <QRCodeVue3 :value="qrData" :key="qrData" :qr-options="{
                                            errorCorrectionLevel: 'H'
                                        }" :image-options="{ hideBackgroundDots: true, imageSize: 0.4, margin: 10 }"
                                            :corners-square-options="cornersSquareOptions"
                                            :corners-dot-options="cornersDotOptions" :dots-options="dotsOptions"
                                            :image="qrIcon" :download="false" />

                                        <p class="text-center mt-4" v-if="sharedText ? true : false">{{ sharedText }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch, nextTick, onUnmounted } from 'vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import QRCodeVue3 from 'qrcode-vue3';

import { QrcodeStream } from 'vue-qrcode-reader';

import '@/assets/sass/pages/helpdesk.scss';
import '@/assets/sass/pages/coming-soon/style.scss';
import { useMeta } from '@/composables/use-meta';



useMeta({ title: 'COB Share' });

const store = useStore();
const sharedText = computed(() => store.state.sharedText);
const qrIcon = ref('facebook.svg');
const qrData = ref('');
const qrInput = ref('');
const scannerError = ref('');
const cornersSquareOptions = ref({ type: 'extra-rounded', color: '#34495E' });
const cornersDotOptions = ref({ type: undefined, color: '#1877F2' });
const dotsOptions = ref({ type: 'rounded', color: '#1877F2', gradient: { type: 'linear', rotation: 0, colorStops: [{ offset: 0, color: '#1877F2' }, { offset: 1, color: '#34495E' }] } });


//QR SCANNER
// Define reactive variables
const qrScannerModal = ref(null);
const scannedData = ref(null);
const loading = ref(false);
const destroyed = ref(true);

// Method to open QR scanner modal
const openQRScannerModal = () => {
    destroyed.value = false;
    const modalInstance = new bootstrap.Modal(qrScannerModal.value);
    modalInstance.show();
};


// Function to handle modal opening
const onModalShow = () => {
    console.log('Modal opened');
    rescan();
    // Do something when modal is shown
};

// Function to handle modal hiding
const onModalHide = () => {
    console.log('Modal hidden');
    showModal.value = false;
    loading.value = false;
    // Do something when modal is hidden
};

// Function to handle camera on event
const onCameraOn = () => {
    console.log('Camera is on');
    loading.value = false;
};

// Asynchronous function to reload
const rescan = async () => {
    destroyed.value = true;
    await nextTick();
    destroyed.value = false;
    loading.value = true;
    scannedData.value = null;
};

function onError(err) {
    scannerError.value = `[${err.name}]: `

    if (err.name === 'NotAllowedError') {
        scannerError.value += 'you need to grant camera access permission'
    } else if (err.name === 'NotFoundError') {
        scannerError.value += 'no camera on this device'
    } else if (err.name === 'NotSupportedError') {
        scannerError.value += 'secure context required (HTTPS, localhost)'
    } else if (err.name === 'NotReadableError') {
        scannerError.value += 'is the camera already in use?'
    } else if (err.name === 'OverconstrainedError') {
        scannerError.value += 'installed cameras are not suitable'
    } else if (err.name === 'StreamApiNotSupportedError') {
        scannerError.value += 'Stream API is not supported in this browser'
    } else if (err.name === 'InsecureContextError') {
        scannerError.value +=
            'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
    } else {
        scannerError.value += err.message
    }
}

/*** track functons ***/

function paintOutline(detectedCodes, ctx) {
    for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = 'red'

        ctx.beginPath()
        ctx.moveTo(firstPoint.x, firstPoint.y)
        for (const { x, y } of otherPoints) {
            ctx.lineTo(x, y)
        }
        ctx.lineTo(firstPoint.x, firstPoint.y)
        ctx.closePath()
        ctx.stroke()
    }
}
function paintBoundingBox(detectedCodes, ctx) {
    for (const detectedCode of detectedCodes) {
        const {
            boundingBox: { x, y, width, height }
        } = detectedCode

        ctx.lineWidth = 2
        ctx.strokeStyle = '#007bff'
        ctx.strokeRect(x, y, width, height)
    }
}
function paintCenterText(detectedCodes, ctx) {
    for (const detectedCode of detectedCodes) {
        const { boundingBox, rawValue } = detectedCode

        const centerX = boundingBox.x + boundingBox.width / 2
        const centerY = boundingBox.y + boundingBox.height / 2

        const fontSize = Math.max(12, (50 * boundingBox.width) / ctx.canvas.width)

        ctx.font = `bold ${fontSize}px sans-serif`
        ctx.textAlign = 'center'

        ctx.lineWidth = 3
        ctx.strokeStyle = '#35495e'
        ctx.strokeText(detectedCode.rawValue, centerX, centerY)

        ctx.fillStyle = '#5cb984'
        ctx.fillText(rawValue, centerX, centerY)
    }
}
const trackFunctionOptions = [
    { text: 'nothing (default)', value: undefined },
    { text: 'outline', value: paintOutline },
    { text: 'centered text', value: paintCenterText },
    { text: 'bounding box', value: paintBoundingBox }
]
const trackFunctionSelected = ref(trackFunctionOptions[1])


const reset = () => {
    scannedData.value = null;
};

const isValidLink = (link) => {
    if (link === null || link === undefined) return false;
    const linkPattern = /^(ftp|http[s]?):\/\/?([^ "]+)$/;
    const isValid = linkPattern.test(link) || linkPattern.test("http://" + link)
    return isValid
};

const onDetect = (detectedCodes) => {
    if (detectedCodes.length > 0) {
        // detecedCodes is an array of detected QR codes get first rawValue in string
        const codeValue = detectedCodes[0].rawValue;
        scannedData.value = codeValue;
        loading.value = false; // Set loading to false when QR code is detected
        destroyed.value = true;
    }
};


const redirect = () => {
    // Redirect user to the link in the QR code
    //check if scannedData is valid link then redirect
    if (isValidLink(scannedData.value)) {
        window.location.href = scannedData.value;
    } else {
        alert('Invalid link');
    }
};

onMounted(() => {
    qrData.value = sharedText.value;
    qrInput.value = sharedText.value;
    // Attach event listener to modal for showing event
    qrScannerModal.value.addEventListener('show.bs.modal', onModalShow);
    // Attach event listener to modal for hiding event
    qrScannerModal.value.addEventListener('hide.bs.modal', onModalHide);
});

onUnmounted(() => {
    // Remove event listener when component is unmounted to prevent memory leaks
    try {
        qrScannerModal.value.removeEventListener('show.bs.modal', onModalShow);
        qrScannerModal.value.removeEventListener('hide.bs.modal', onModalHide);
    } catch (error) {
        console.log('Error removing event listener', error);
    }
});

const generateQR = () => {
    if (isValidLink(qrInput.value)) {
        qrData.value = qrInput.value;
    } else {
        alert('Invalid link');
    }
};

watch(qrData, (newValue) => {
    if (newValue === null || newValue === undefined) return;
    if (isValidLink(newValue)) {
        // modify this code to check if the qrData value includes platform name
        // first convert string to lowercase then check if it includes platform name
        const lowerCaseValue = newValue.toLowerCase();
        console.log(lowerCaseValue)
        if (lowerCaseValue.includes('facebook'))
            updateOptions('facebook');
        else if (lowerCaseValue.includes('instagram'))
            updateOptions('instagram');
        else if (lowerCaseValue.includes('twitter'))
            updateOptions('twitter');
        else if (lowerCaseValue.includes('youtube'))
            updateOptions('youtube');
        else if (lowerCaseValue.includes('linkedin'))
            updateOptions('linkedin');
        else
            updateOptions('other');
    }

});

watch(sharedText, (newValue) => {
    if (newValue === null || newValue === undefined) return;
    if (isValidLink(newValue)) {
        qrInput.value = newValue;
        qrData.value = newValue;
    }
});

function updateOptions(platform) {
    switch (platform) {
        case 'facebook':
            qrIcon.value = 'facebook.svg';
            cornersDotOptions.value.color = '#1877F2';
            dotsOptions.value.color = '#1877F2';
            dotsOptions.value.gradient.colorStops[0].color = '#1877F2';
            break;
        case 'instagram':
            qrIcon.value = 'instagram.svg';
            cornersDotOptions.value.color = '#E4405F';
            dotsOptions.value.color = '#E4405F';
            dotsOptions.value.gradient.colorStops[0].color = '#E4405F';

            break;
        case 'twitter':
            qrIcon.value = 'twitter.svg';
            cornersDotOptions.value.color = '#1DA1F2';
            dotsOptions.value.color = '#1DA1F2';
            dotsOptions.value.gradient.colorStops[0].color = '#1DA1F2';
            break;
        case 'youtube':
            qrIcon.value = 'youtube.svg';
            cornersDotOptions.value.color = '#CD201F';
            dotsOptions.value.color = '#CD201F';
            dotsOptions.value.gradient.colorStops[0].color = '#CD201F';
            break;
        case 'linkedin':
            qrIcon.value = 'linkedin.svg';
            cornersDotOptions.value.color = '#0077b5';
            dotsOptions.value.color = '#0077b5';
            dotsOptions.value.gradient.colorStops[0].color = '#0077b5';
            break;
        default:
            qrIcon.value = 'other.svg';
            cornersDotOptions.value.color = '#131418';
            dotsOptions.value.color = '#131418';
            dotsOptions.value.gradient.colorStops[0].color = '#131418';
            break;
    }
}
</script>
