<template>
    <div :class="[$store.state.layout_style, $store.state.menu_style]">
        <component v-bind:is="layout"></component>
    </div>
</template>
<script setup>
    import { computed, ref, onMounted } from 'vue';
    import Swal from 'sweetalert2';
    import '@/assets/sass/app.scss';

    import { useMeta } from '@/composables/use-meta';
    import { useStore } from 'vuex';

    useMeta({ title: 'COB Share App' });

    const store = useStore();

    const layout = computed(() => {
        return store.getters.layout;
    });

    // Define a reactive reference for the deferredPrompt
const deferredPrompt = ref(null);

// Function to show install modal
const showInstallModal = () => {
  Swal.fire({
    toast: true,
    position: 'bottom',
    showConfirmButton: true,
    confirmButtonText: 'Install',
    showCancelButton: false,
    showCloseButton: true,
    confirmButtonColor: '#b31f20',
    timer: 3000,
    timerProgressBar: true,
    width: 350,
    padding: '0.5rem',
    backdrop: false,
    html: `
      <img src="/img/cob-share-logo-white.png" style="vertical-align: middle; width: 30px; height: 30px; margin-right: 10px;">
      <span style="vertical-align: middle; color:white">Install Our Awesome App</span>
    `,
    customClass: {
      actions: 'd-flex justify-content-center',
    },
  }).then((result) => {
    if (result.isConfirmed && deferredPrompt.value) {
      deferredPrompt.value.prompt();
      deferredPrompt.value.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        // Reset deferredPrompt after prompt is used
        deferredPrompt.value = null;
      });
    }
  });
};

// Hook to show install modal when component is mounted
onMounted(() => {
  // Listen for beforeinstallprompt event
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Store the event to use later
    deferredPrompt.value = e;
    // Show the install modal
    showInstallModal();
  });
});
</script>
<script>
    // layouts
    import appLayout from './layouts/app-layout.vue';
    import authLayout from './layouts/auth-layout.vue';

    export default {
        components: {
            app: appLayout,
            auth: authLayout,
        },
    };
</script>
