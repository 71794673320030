<template>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <nav ref="menu" id="sidebar">
            <div class="shadow-bottom"></div>

            <perfect-scrollbar class="list-unstyled menu-categories" tag="ul"
                :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">
                <li class="menu">
                    <router-link to="/" class="dropdown-toggle" @click="toggleMobileMenu">
                        <div class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-home">
                                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                <polyline points="9 22 9 12 15 12 15 22"></polyline>
                            </svg>
                            <span>Home</span>
                        </div>
                    </router-link>
                </li>
                <hr>
                <!-- <li class="menu">
                    <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#datatables"
                        aria-controls="datatables" aria-expanded="false">
                        <div class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-layers">
                                <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                                <polyline points="2 17 12 22 22 17"></polyline>
                                <polyline points="2 12 12 17 22 12"></polyline>
                            </svg>
                            <span>{{ $t('datatables') }}</span>
                        </div>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-chevron-right">
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id="datatables" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
                        <li>
                            <router-link to="/tables/basic" @click="toggleMobileMenu">Basic</router-link>
                        </li>
                        <li>
                            <router-link to="/tables/striped" @click="toggleMobileMenu">Striped Table</router-link>
                        </li>
                        <li>
                            <router-link to="/tables/order-sorting" @click="toggleMobileMenu">Order Sorting</router-link>
                        </li>
                        <li>
                            <router-link to="/tables/multi-column" @click="toggleMobileMenu">Multi Column</router-link>
                        </li>
                        <li>
                            <router-link to="/tables/multiple-tables" @click="toggleMobileMenu">Multiple
                                Tables</router-link>
                        </li>
                        <li>
                            <router-link to="/tables/alt-pagination" @click="toggleMobileMenu">Alt. Pagination</router-link>
                        </li>
                        <li>
                            <router-link to="/tables/custom" @click="toggleMobileMenu">Custom</router-link>
                        </li>
                        <li>
                            <router-link to="/tables/range-search" @click="toggleMobileMenu">Range Search</router-link>
                        </li>
                        <li>
                            <router-link to="/tables/export" @click="toggleMobileMenu">Export</router-link>
                        </li>
                        <li>
                            <router-link to="/tables/live-dom-ordering" @click="toggleMobileMenu">Live DOM
                                ordering</router-link>
                        </li>
                        <li>
                            <router-link to="/tables/miscellaneous" @click="toggleMobileMenu">Miscellaneous</router-link>
                        </li>
                    </ul>
                </li> -->
               <li class="menu">
                    <router-link to="/pages/about-us" class="dropdown-toggle" @click="toggleMobileMenu">
                        <div class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users" data-v-5522efca=""><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                            <span>About Us</span>
                        </div>
                    </router-link>
                </li>
                
                <li class="menu">
                    <router-link to="/pages/contact-us" class="dropdown-toggle" @click="toggleMobileMenu">
                        <div class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail" data-v-5522efca=""><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                            <span>Contact Us</span>
                        </div>
                    </router-link>
                </li>

                <li class="menu">
                    <router-link to="/pages/privacy-policy" class="dropdown-toggle" @click="toggleMobileMenu">
                        <div class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shield" data-v-5522efca=""><path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path></svg>
                            <span>Privacy Policy</span>
                        </div>
                    </router-link>
                </li>
                <li class="menu">
                    <router-link to="/pages/terms-conditions" class="dropdown-toggle" @click="toggleMobileMenu">
                        <div class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text" data-v-5522efca=""><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                            <span>Terms & Conditions</span>
                        </div>
                    </router-link>
                </li>
            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
//import router
import router from '@/router';
const store = useStore();

const menu_collapse = ref('dashboard');

onMounted(() => {
    // const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
    // if (selector) {
    //     const ul = selector.closest('ul.collapse');
    //     if (ul) {
    //         let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
    //         if (ele) {
    //             ele = ele[0];
    //             setTimeout(() => {
    //                 ele.click();
    //             });
    //         }
    //     } else {
    //         selector.click();
    //     }
    // }
    
});

const navigateHome = () => {
   // on click navigate to home page with router
    store.commit('toggleSideBar', false);
    router.push('/');
};

const toggleMobileMenu = () => {
    if (window.innerWidth < 991) {
        store.commit('toggleSideBar', !store.state.is_show_sidebar);
    }
};
</script>
