<template>
    <!-- BEGIN FOOTER -->
    <div class="footer-wrapper">
        <div class="footer-section f-section-1">
            <p class="text-center">Copyright © 2024 <a target="_blank" href="https://callobuzz.com">Call O Buzz</a>, All rights reserved.</p>
        </div>
        <div class="footer-section f-section-2">
            <p class="">
                Developed By <a target="_blank" href="https://callobuzz.com">COB</a>
            </p>
        </div>
    </div>
    <!-- END FOOTER -->
</template>
