import { useHead } from '@vueuse/head';
import { unref, computed } from 'vue';

let siteTitle = 'Call O Buzz Share';
let separator = '|';

const defaultOgImage = 'https://share.callobuzz.com/img/cob-share-logo-white-bg.png'; // Replace with your default image URL
const defaultOgDescription = 'QR Scanner & Generator with monetization option for promotors and reach provider for businesses';


export const usePageTitle = (pageTitle) =>
    useHead(
        computed(() => ({
            title: `${unref(pageTitle)} ${separator} ${siteTitle}`,
        }))
    );



export const useMeta = (data) => {
    return useHead({
        ...data,
        title: `${data.title} | Call O Buzz`,
        meta: [
            // Open Graph meta tags
            { property: 'og:title', content: data.title || siteTitle },
            { property: 'og:description', content: data.description || defaultOgDescription },
            { property: 'og:image', content: data.image || defaultOgImage }, // Use default image if not provided

            // Other meta tags as needed
            // For example:
            { name: 'description', content: data.meta_description || defaultOgDescription },
            { name: 'keywords', content: 'qr scanner, qr generator, affiliates, sharing, qr code' }
        ]
    });
};
